<script>
import ModuleBase from "../ModuleBase.vue";

export default {
    name: "CustomQuestionBase",
    mixins: [ModuleBase],
    props: {
        isCustomQuestionShown: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        isCustomQuestionShown: function () {
            this.$nextTick(async () => {
                await this.$ModuleBase_validate();
            });
        }
    },
}
</script>
