<template>
  <div
    :id="wrapperId"
    :class="settings.required ? 'col-12 m-0 p-0 gf-custom-question-free-text required field' : ' col-12 m-0 p-0 gf-custom-question-free-text field'"
  >
    <span v-html="parsedDescription" />
    <input
      :id="textId"
      v-model="textValue"
      :name="textName"
      :class="{
        'is-invalid':
          validation.hasError('textValue') &&
          validation.isTouched('textValue'),
      }"
      type="text"
      :placeholder="settings.placeholderText"
    >
  </div>
</template>
<script>
import CustomQuestionBase from "./CustomQuestionBase.vue";

export default {
  name: "Freetext",
  mixins: [CustomQuestionBase],
  props: {
    values: {
      type: Object,
      default: function() {
        return { }
      }
    },
  },
  data() {
    return {
      parsedDescription: '',
      textValue: '',
    }
  },
  computed: {
    wrapperId() {
      return `custom-question-freetext-wrapper-${this.settings.uniqueIdentifier}`
    },
    textId() {
      return `custom-question-freetext-${this.settings.uniqueIdentifier}`
    },
    textName() {
      return `freetext-custom-${this.settings.uniqueIdentifier}`
    },
  },
  watch: {
    textValue: function(val) {
      this.$nextTick(async () => {
        this.storeUpdatedValue();
        await this.$ModuleBase_validate();
      });
    },
  },
  mounted() {
    this.logger.logInfo('FreeText component loaded. Settings:', this.settings)
    this.parsedDescription = this.settings.description
  },
  methods: {
    storeUpdatedValue() {
      var metaDataValue = {
        metaType: this.constants.PaymentMetaType.Custom,
        metaValueType: this.constants.PaymentMetaValueType.String,
        name: this.settings.uniqueIdentifier,
        description: this.settings.description,
        value: this.textValue
      }
      this.store.updateCustomQuestionValue(metaDataValue)
    },
  },
  validators: {
    textValue(val) {
        if (this.settings.required && this.isCustomQuestionShown) {
            return this.validator
                .value(val)
                .required()
        }
    },
   }
}
</script>
<style>
::placeholder {
  opacity: 0.5;
  font-style: italic;
}
</style>
