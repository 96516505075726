<template>
  <div />
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
import GfAnalyticsMixin from '../GfAnalyticsMixin.vue';

export default {
    mixins: [GfAnalyticsMixin, ModuleBase],
    watch: {
        'sharedState.paymentResponse': {
        deep: true,
        handler() {
            this.logger.logInfo(`paymentResponse Changed`, this.sharedState.paymentResponse);  
            this.sendTrackingData();
        }
        }
    },
    methods:{
        sendTrackingData(){
            this.logger.logInfo(`adding to dataLayer`, this.sharedState.paymentResponse.TagManagerData);  
            if(this.sharedState.paymentResponse.TagManagerData){
            this.track(this.sharedState.paymentResponse.TagManagerData);
            }
        }
    },
};
</script>