<template lang="html">
  <div
    v-if="captchaEnabled"
    class="col-12 m-0 p-0"
  >
    <div class="col-md-8 d-none">    
      <vue-recaptcha        
        ref="invisibleRecaptcha"
        size="invisible"
        :sitekey="captchaPublicKey"
        @verify="RecaptchaAsVerified"
      />    
    </div>
    <div     
      class="row content-center"
    >
      <span class="google-protect">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.</span>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
import GfConfigurationClient from '../../../../common/GfConfigurationClient.vue';
import CaptchaInitializer from "./CaptchaInitializer.vue";
export default {
    name: "Captcha",
    mixins: [ModuleBase, GfConfigurationClient, CaptchaInitializer],
    props: {
        captchaPublicKey:{
            type: String,
            default: ""
        }
    },
    data() {
        return {
            inApp: false,
            entityConfigSettings: [
              {
                type: "Security",
                subType: "Turing"
              }
            ],
            listSettings: []
        };
    },
    
    computed: {
      captchaEnabled() {
        this.logger.logInfo(`Captcha Enabled check current gateway`, JSON.stringify(this.sharedState.currentGateway));
        var enableCaptcha = _.get(this.sharedState.currentGateway, 'additionalSettings.enableCaptcha', 'True');
        enableCaptcha = enableCaptcha == 'True';
        this.logger.logInfo(`Captcha Enabled check`, enableCaptcha);

        return !(this.captchaPublicKey === '' || this.captchaPublicKey === null) && enableCaptcha
      }
    },

    watch: {
        'sharedState.paymentResponse': {
        deep: true,
        handler() {
            this.logger.logInfo(`paymentResponse Changed`, this.sharedState.paymentResponse);
            if(this.sharedState.paymentResponse && this.sharedState.paymentResponse.Status && !this.sharedState.paymentResponse.Status.Success)
            {
              this.$refs.invisibleRecaptcha.reset();
            }
          }
        }
    },

    mounted() {
        this.logger.logInfo(`Captcha module loaded`);
        this.loadScript();
    },
    methods: {
      $ModuleBase_handleEntityConfigResults(results) {
        this.listSettings = results[0]
       this.getSettings();
      },

      async $ModuleBase_executeBeforePayment() {      
        this.isValid = false;
        this.recaptchaVerified = false;
        this.executeCaptcha();
        return await this.getCaptchaResponse(); 
      },

      async getCaptchaResponse() {
        const timer = ms => new Promise(res => setTimeout(res, ms))
        for (var i = 0; i <10 ; i++) {
          this.logger.logInfo("Waiting for captcha response before completing.")
          await timer(50);
          if (this.recaptchaVerified === true){
            this.isValid = true;
            return true
          }
        }
        this.logger.logInfo("Captcha never completed. Returning false")
        return false
      },

      $ModuleBase_validate() {
        if(this.captchaEnabled)
            return this.isValid;

        this.isValid = true;
        return this.isValid;
      },
      getSettings(){
        this.logger.logInfo(`Captcha settings`, this.listSettings);        
        if(this.listSettings.Settings)
          this.captchaPublicKey = this.listSettings.Settings.PublicKey;
        if (this.store.state.captchaToken && this.store.state.captchaToken.length > 0) {
            if(this.captchaEnabled){
              this.$refs.invisibleRecaptcha.reset();
            }
        }
      },
      loadScript(){
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
        recaptchaScript.async = true
        recaptchaScript.defer = true
        document.head.appendChild(recaptchaScript)
      }
    }
};
</script>
<style>
.grecaptcha-badge { visibility: hidden; }

.google-protect {
  color: darkgray;
  font-size: 12px !important;
  text-align: center;
}
.content-center{
  justify-content: center;
}
</style>