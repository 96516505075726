<script>
import logger from "../../../common/logger";
import demoSubmission from "../../../common/paymentSubmissions/demoSubmission";
import GfPaymentStatusManager from "../../../common/GfPaymentStatusManager.vue"
import * as GfLocalisation from '@gf/gf-localisation'

export default {

  mixins: [GfPaymentStatusManager],
  props: {
      submissionProcessor: {
          type: Object,
          default:() => null
      }
  },

  data() {
      return {
        pollingId: null,
        GfLocalisation,
      }
  },

  methods: {
    async $_PaymentSubmissionMixin_SubmitPayment() {
      this.processing = true;
      this.store.overrideProceedToNext(false);
      if (this.store.state.isProcessingPayment === false) {
        this.store.setProcessingPayment(true);
        var message = {
          Application: "GoFundraise",
          Type: "Payment",
          Action: "Donation",
          Data: null,
        };

        if (this.demo && !this.paymentSubmissionProcessor) {
          this.paymentSubmissionProcessor = demoSubmission;
        }

        logger.logInfo("sending token details", this.tokenDetails);
        logger.logInfo("payload details", this.store.getPayload());
        message.Data = await this.paymentSubmissionProcessor.submit(
          this.store.getPayload(),
          this.store.state.csrfToken
        );

        logger.logInfo('$_PaymentSubmissionMixin_SubmitPayment -> message.Data', message.Data);

        this.store.setPaymentResponse(message.Data);
        this.processing = false;

        if (this.onPaymentResponse !== "" && this.onPaymentResponse) {
          window[this.onPaymentResponse](message.Data);
        }

        if (!message.Data || (message.Data && !message.Data.Status.Success)) {
          this.store.overrideProceedToNext(true);
        } else {
          if (this.store.getPayload().WaitForCompletion) {
            this.navigateToNextSlide();
            this.store.setProcessingPayment(false);
          } else {
            this.store.setProcessingPayment(true);
            this.processing = true;
            this.store.setPaymentClientReference(message.Data.ReferenceId)
            await this.$_GfPaymentStatusManager_RetrieveStatusUpdates(message.Data.ReferenceId,
                this.store.state.pollingTimeoutInMs,
                this.store.state.pollingPeriodInMs,
                this.handlePaymentStatusReceived,
                this.handleSucessMessage,
                this.handlePendingActions,
                this.handleFailure);
          }
        }
      }
    },

    createSuccessMessageToPost(data) {
      
      const additionalData = {
        Customer: data.Payment.PaymentDetails,
        Beneficiary: data.Beneficiary,
        Payments: data.PaymentItems.filter(x => x.ProductId != 4)
      };

      return {
          Application: "GoFundraise",
          Type: "Payment",
          Action: "Donation",
          Data: {
              Status: {
                  Success: true,
                  Message: "Payment Successful",
                  AdditionalData: additionalData
              }
          }
      };
    },    

    $_PaymentSubmissionMixin_OnClientActionsResponse(data) {
        if (data && !data.success)
            this.handleFailure(this.pollingId, data);
    },

    async $_PaymentSubmissionMixin_UpdatePaymentRequest(requestType, additionalData) {
      return await this.$_GfPaymentStatusManager_UpdatePaymentRequest(
            requestType,
            additionalData);
    },

    handlePaymentStatusReceived(intervalId, message) {
      this.pollingId = intervalId;
      this.store.setPaymentResponse(message);
    },

    handleSucessMessage(intervalId) {
        if (this.processing ) {
        clearInterval(intervalId);
        this.store.setDisplayAlternateButton(false)
        this.navigateToCompletion();
        this.store.overrideProceedToNext(true);
        this.processing = false;
        this.store.setProcessingPayment(false);

        const successMessage = this.createSuccessMessageToPost(
                    this.store.state.paymentResponse
                );
        window.parent.postMessage(JSON.stringify(successMessage), "*");
        }
    },

    handleFailure(intervalId, data) {
      if (this.processing) {
        var message = typeof this.GfLocalisation.getLocale('errorCodes')["Unknown_Error"] != 'undefined'
          ? this.GfLocalisation.getLocale('errorCodes')["Unknown_Error"].prettyDescription
          : '';
        var mappedErrorCode = this.GfLocalisation.getLocale('errorCodes')[data.FailureCode];
        if(mappedErrorCode && mappedErrorCode.prettyDescription)
        {
          message =  mappedErrorCode.prettyDescription + (mappedErrorCode.showStatusMessage ? `: ${data.FailureMessage}` : "");
        }
        clearInterval(intervalId);
        this.store.clearPaymentPendingActions();
        this.store.state.isPendingPaymentActions = false;
        this.store.setPaymentResponse({
            Status: {
                Success: false,
                Message: message
            }
        });
        this.processing = false;
        this.store.setProcessingPayment(false);

        window.parent.postMessage(JSON.stringify({
                              Application: "GoFundraise",
                              Type: "Payment",
                              Action: "Donation",
                              Data: this.store.state.paymentResponse                              
        }), "*");

      }
    },

    handlePendingActions(intervalId, data) {
        this.store.setPaymentPendingActions(true, data);
    }
  }
};
</script>
