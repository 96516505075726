<template>
  <div
    v-show="isCustomQuestionShown"
    id="gf-epl-dollar-handles"
    class="row m-0 pb-4 w-100"
  >
    <component
      :is="settings.type"
      :settings="settings"
      :displayed-on-page-index="displayedOnPageIndex"
      @action="(eventData) => this.$emit('action', eventData)"
    />
  </div>
</template>
<script>
import { actions } from "../../../../common/conditionalLogic/ConditionalLogicConfig";
import ModuleBase from "../ModuleBase.vue";
import Checkbox from "./Checkbox.vue";
import Checkboxmultiple from "./CheckboxMultiple.vue";
import Options from "./Options.vue";
import Freetext from "./FreeText.vue";

export default {
	name: "CustomQuestion",
    components: {
      Checkbox,
      Checkboxmultiple,
	    Options,
      Freetext,
    },
	mixins: [ModuleBase],
  data() {
    return {
      isParentModule: true,
    }
  },
	mounted() {
		this.logger.logInfo(this.settings,'Custom question Module loaded')

        this.registerAction(actions.show, () => this.isCustomQuestionShown = true);
        this.registerAction(actions.hide, () => this.isCustomQuestionShown = false);
	}
};
</script>

