<template>
  <div
    :id="`gf-epl-text-${uniqueId}`"
    class="col-12 m-0 p-0"
    :class="settings.class"
  >
    <span v-html="parsedContent" />
  </div>
</template>
<script>
import GfTextTemplateMappings from './GfTextTemplateMappings';
import ModuleBase from '../ModuleBase.vue'
import _ from 'lodash'

export default {
  mixins: [ModuleBase],

  data() {
    return {
      parsedContent: ''
    }
  },

  created() {
    this.initializeWatchers()
  },

  methods: {
    initializeWatchers() {
      let sharedStates = ['paymentResponse', 'totalAmount', 'amount', 'currencyInfo.symbol', 'addOnamount', 'coveringCost', 'customerDetails.FirstName', 'customerDetails.LastName']

      this.logger.logInfo('GfText -> initializedWatchers', sharedStates)

      sharedStates.forEach(sharedStateProp => {
        this.$watch(`sharedState.${sharedStateProp}`, () => {
          this.logger.logInfo(`GfText -> watch -> sharedState.${sharedStateProp}`, this.sharedState)
          this.replaceTemplateVariable()
        }, {
          deep: true,
        })
      })
    },

    $ModuleBase_afterBaseMounted() {
      this.parsedContent = this.settings.content
      this.replaceTemplateVariable()
    },

    replaceTemplateVariable() {
      var self = this
      var updatedContent = this.settings.content

      Object.keys(GfTextTemplateMappings).forEach(mappingKey => {
         updatedContent = updatedContent.replace(mappingKey, GfTextTemplateMappings[mappingKey])
        }
      );

      var templateExpression = /\$[\w\.|\[|\]]+\$/g
      var templateVariables = [...updatedContent.matchAll(templateExpression)]
      this.logger.logInfo("Found template variables: ", templateVariables)

      if (templateVariables.length == 0) {
        return
      }

      templateVariables.forEach(templateVar => {
        var templateVal
        var variable = templateVar[0].replace(/\$/g, '')
        var sharedStateRegExp = /^sharedState\./g

        templateVal = variable.match(sharedStateRegExp)
          ? _.get(self.sharedState, variable.replace(sharedStateRegExp, ''))
          : _.get(self.sharedState.paymentResponse, variable)

        if (templateVal) {
          this.logger.logInfo(`Replacing ${templateVar} with ${templateVal}.`)
          updatedContent = updatedContent.replace(templateVar, templateVal)
        } else {
          this.logger.logInfo(`Replacing ${templateVar} with an empty string.`)
          updatedContent = updatedContent.replace(templateVar, '')
        }
      })

      this.parsedContent = updatedContent
    }
  }
}
</script>
