<template>
  <div
    id="gf-epl-fundraising-message"
    class="col-12 m-0 p-0"
  >
    <div class="field">
      <label v-if="!settings.isDenseMode">{{ settings.text }}</label>
      <div class="ui input">
        <input
          id="message"
          v-model="message"
          :placeholder="settings.isDenseMode && settings.text"
          type="text"
        >
      </div>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";
export default {
  mixins: [ModuleBase],

  data() {
    return {
      message: ''
    }
  },

  watch: {
    message(val) {
      this.store.updateMessage(val);
    }
  }
};
</script>
<style scoped>
#gf-epl-fundraising-message input[type="text"] {
	margin: 0;
    outline: 0;
    tap-highlight-color: hsla(0,0%,100%,0);
    background: #fff;
    border: 1px solid rgba(34,36,38,.2);
    color: #000;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 0 0 transparent inset;
    box-shadow: inset 0 0 0 0 transparent;
    -webkit-transition: color .1s ease,border-color .1s ease;
    transition: color .1s ease,border-color .1s ease;
	  line-height: 1.6em;
    padding: 13px;
	width: 99.8%;
}

.field{
	padding-bottom: 24px;
}

.field label{
	margin-bottom: 5px;
}
</style>
