<template lang="html">
  <div>
    <div id="clicktopayWidget" />

    <div
      v-if="!loadFailure"
      class="panel panel-default py-3"
    >
      <form
        id="cardForm"
        action="/"
        method="post"
        class="panel-body braintree-fields px-0"
      >
        <div class="row">
          <div
            id="container-addCard"
            class="col-12"
            style="padding-left:30px;height:auto;"
          />
        </div>
        <div class="row">
          <button
            id="addCard-submit"
            type="submit"
            class="btn btn-secondary"
            style="display:none"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
    <div
      v-show="showWrapper"
      id="payfurl-wrapper"
      class="payment-button-wrapper"
    >
      <div
        v-show="!isButtonEnabled"
        id="paypal-button-container-disabled"
      >
        <button
          id="payfurl-clicktopay-donate-disabled"
          class="btn btn-secondary border-0 gf-driver-button"
          style="width: 100%; text-align: center;"
        >
          Donate
        </button>
      </div>
      <div
        v-show="isButtonEnabled"
        id="payfurl-button-container"
      >
        <button
          id="payfurl-clicktopay-donate"
          class="btn btn-primary border-0 gf-driver-button"
          style="width: 100%; text-align: center;"
        >
          Donate
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import GfClickToPayInitializer from './GfClickToPayInitializer.vue';
import GfPaymentComponent from '../../../common/GfPaymentComponent.vue';
import ClickToPayPaymentRequestor from './ClickToPayPaymentRequestor';
import VS2 from "@gf/vue-script2";
import logger from '../../../common/logger'
import GfCardTypes from "../../../common/GfCardTypes";

export default {
  name: 'GfClickToPayCreditCard',
  components: {  },
  mixins: [GfClickToPayInitializer, GfPaymentComponent],
  props: {
    preFillData: {
      type: Object,
      default: () => {}
    },
  },
  data: function() {
    return {
      clickToPayPaymentRequestor: new ClickToPayPaymentRequestor(),
      hostedFieldInstance: '',
      additionalData: { cardholderName: '' },
      ignoreCardholderName: true,
      alwaysUseCardholderName: false,
      cardTypeToHighlight: 'all',
      cardholderNameInvalid: true,
      scriptLoader: VS2,
      showWrapper: false,
      isButtonEnabled: false,
      paymentTypeName: 'clicktopay-credit-card',
      customerDetails: null,
      currencyInfo: null,
      payfurl: null,
      step3TabDisplay: 'none',
      orderId: null,
      processingActions: false,
    }
  },

  computed: {
    isDisabled() {
      return this.disabled;
      }
  },
  watch: {
    'store.state.totalAmount': function(val) {
      if (this.payfurl)
        this.payfurl.setAmount(val);
    },
    'paymentData.amount': function(val) {
      if (this.payfurl)
        this.payfurl.setAmount(val);
    },
    'currencyInfo.code': function (val) {
      if (this.payfurl)
        this.payfurl.setCurrency(val);
    },
    'paymentData.currencyCode': function (val) {
      if (this.payfurl)
        this.payfurl.setCurrency(val);
    },
    step3TabDisplay: function (val) {
          let vm = this;
          if (val == "") {
              if (!this.store.state.recurringDonation) {
                  let supportedCardBrands = ["mastercard", "maestro", "visa", "amex"];
                  if (this.additionalProperties.supportedCardBrands)
                      supportedCardBrands = this.additionalProperties.supportedCardBrands;

                  this.payfurl.addCard("container-addCard", this.additionalProperties.providerId, {
                      amount: this.store.state.totalAmount,
                      currency: this.currencyInfo.code,
                      threeDSEmail: this.customerDetails.EmailAddress,
                      requiredFields: {},
                      clickToPayEnabled: true,
                      createNetworkToken: false,
                      clickToPayOptions: this.getClickToPayOptions(supportedCardBrands, this.additionalProperties.dpaBillingPreference, this.additionalProperties.saveCard),
                      style: "compact"
                  });
              }
              else {
                  this.payfurl.addCard("container-addCard", this.additionalProperties.providerId, {
                      amount: this.store.state.totalAmount,
                      currency: this.currencyInfo.code,
                      requiredFields: {},
                      style: "compact",
                      clickToPayEnabled: false,
                      createNetworkToken: true,
                      tokenAuthenticationEnabled: this.additionalProperties.tokenAuthenticationEnabled,
                      networkTokenOptions: {
                          acquirerMerchantId: this.additionalProperties.acquirerMerchantId,
                          acquirerBIN: this.additionalProperties.acquirerBIN,
                          merchantCategoryCode: this.additionalProperties.merchantCategoryCode,
                          merchantCountryCode: this.additionalProperties.merchantCountryCode,
                          authenticationReasons: ["TRANSACTION_AUTHENTICATION"]
                      }
                  });
              }

              setTimeout(function test() {
                  var node = document.getElementById('payfurl-card-iframe');
                  if (document.body.contains(node)) {
                    var donateButton = document.getElementById('payfurl-clicktopay-donate');
                    // Donate button to force getting clientReferenceId
                    donateButton.onclick = function () {
                      var addCardSubmit = document.getElementById('addCard-submit');
                      addCardSubmit.click();
                    }
                  } else {
                      setTimeout(test, 500);
                  }
              }, 500);
          }
    },
    'store.state.canProceed': {
        deep: true,
        handler: function (val) {
            this.isButtonEnabled = val
        }
    },
    'store.state.regenerateTokenTrigger': {
      deep: true,
      handler: function () {
        logger.logInfo('GfClickToPayCreditCard.watch, regenerateTokenTrigger')
        this.generateToken()
      }
    }
  },

  created: function() {
    if (typeof(this.additionalProperties) === 'undefined')
      return;
  },

  mounted: function() {    
    this.initialize(this.tokenizationKey, this.preFillData, this.onPaymentMethodReceived, this.onPaymentError);
    this.debouncedTokenRequest = _.debounce(this._generateToken, 500);

    var thisInstance = this;
    this.initializePayfurl();

    var observer = new MutationObserver(function (mutationsList, observer) {
        for (var mutation of mutationsList) {
            if (mutation.attributeName == "style") {
                thisInstance.step3TabDisplay = document.getElementById('step-3').style.display;
            }
        }
    });
    if (document.getElementById('step-3'))
        observer.observe(document.getElementById('step-3'), { attributes: true });
  },
  methods: {
      initializePayfurl: function () {
        let vm = this;
        vm.isUpdatePaymentMethod = false;
        var attr = {
            parent: document.head,
            attributes: []
        };
        var scriptUrl = this.additionalProperties.sdkUrl ? this.additionalProperties.sdkUrl : 'https://assets.payfurl.com/v4.6.20.892/js/payfurl.js'
        this.scriptLoader.load(
            scriptUrl,
            attr
        ).then(() => {
            logger.logInfo("initializePayfurl() init called", this.additionalProperties);

            this.payfurl = payfurl.init(
                this.additionalProperties.environment,
                this.tokenizationKey,
                this.additionalProperties.enableDetailedLogging
            );
            this.payfurl.onValidationStatusChange((status, message) => {
                if (status != 'success') {
                    // Clean token / disable Donate button
                    this.clearInputData();
                }
                else {
                    // Create fake token / enable Donate button
                    vm.onPaymentMethodReceived(
                        Date.now().toString(),
                        GfCardTypes.types.MASTERCARD,
                        "",
                        ""
                    );
                }
            });
            this.payfurl.onFailure((message) => {
                logger.logInfo('[SDK onFailure]', message);
            });
            this.payfurl.onSuccess((message) => {
                logger.logInfo('[SDK onSuccess], success', message);

                if (vm.isUpdatePaymentMethod === true) {
                    document.getElementById('container-addCard').innerHTML =
                        "<div style='color: #48b608;background: transparent;font - size: 12px;font - weight: bold;margin - bottom: 20px;'>Verifying card details...</div>";
                }
                else {
                    document.getElementById('container-addCard').innerHTML =
                        "<div style='color: #48b608;background: transparent;font - size: 12px;font - weight: bold;margin - bottom: 20px;'>Payment in progress...</div>";
                }

                if (message.token) {
                    vm.onPaymentMethodReceived(
                        message.token,
                        vm.normaliseCard(message.cardDetails ? message.cardDetails.scheme : undefined),
                        "",
                        "",
                        { forceSubmit: vm.embeddedPaymentMode === true }
                    );
                }

                if (vm.embeddedPaymentMode === false) {
                    vm.$nextTick(() => {
                        logger.logInfo(`GfClickToPayCreditCard.onSuccess() - calling callback function`)
                        vm.callbackFromValidateBeforeSubmit(true, true);
                    });
                }
            });
            this.payfurl.onLoaded((message) => {
                logger.logInfo('[GfClickToPayCreditCard.onLoaded: SDK onLoaded] ' + message);
                logger.logInfo("[GfClickToPayCreditCard.onLoaded: paymentData -]", vm.paymentData)
                if (vm.embeddedPaymentMode === true && !this.store.state.recurringDonation) {
                    vm.onPaymentMethodReceived(
                        Date.now().toString(),
                        GfCardTypes.types.MASTERCARD,
                        "",
                        ""
                    );
                }
            });

            if (this.embeddedPaymentMode === true) {
                let supportedCardBrands = ["mastercard", "maestro", "visa", "amex"];
                if (this.additionalProperties.supportedCardBrands)
                    supportedCardBrands = this.additionalProperties.supportedCardBrands;
                this.payfurl.preloadClickToPay(this.getClickToPayOptions(supportedCardBrands, this.additionalProperties.dpaBillingPreference, this.additionalProperties.saveCard));
            }
            else if (this.paymentData.isRecurring === false && this.paymentData.isUpdatePaymentMethod != true) {
                let supportedCardBrands = ["mastercard", "maestro", "visa", "amex"];
                if (this.additionalProperties.supportedCardBrands)
                    supportedCardBrands = this.additionalProperties.supportedCardBrands;
                this.payfurl.preloadClickToPay(this.getClickToPayOptions(supportedCardBrands, this.additionalProperties.dpaBillingPreference, this.additionalProperties.saveCard));

                this.payfurl.addCard("container-addCard", this.additionalProperties.providerId, {
                    amount: this.paymentData.amount,
                    currency: this.paymentData.currencyCode,
                    threeDSEmail: this.paymentData.email,
                    requiredFields: {},
                    clickToPayEnabled: true,
                    createNetworkToken: false,
                    clickToPayOptions: this.getClickToPayOptions(supportedCardBrands, this.additionalProperties.dpaBillingPreference, this.additionalProperties.saveCard),
                    style: "compact"
                });

                setTimeout(function test() {
                    var node = document.getElementById('payfurl-card-iframe');
                    if (document.body.contains(node)) {
                        vm.validateBeforeSubmit = vm._autoClickAddCardAndReturnNotValidated;
                    } else {
                        setTimeout(test, 500);
                    }
                }, 500);
            }
            else {
                logger.logInfo(`[GfClickToPayCreditCard] not using click to pay mode as we have recurring or are updating card`)
                vm.isUpdatePaymentMethod = true;
                this.payfurl.addCard("container-addCard", this.additionalProperties.providerId, {
                    amount: this.paymentData.amount,
                    currency: this.paymentData.currencyCode,
                    requiredFields: {},
                    style: "compact",
                    clickToPayEnabled: false,
                    createNetworkToken: true
                });

                setTimeout(function test() {
                    var node = document.getElementById('payfurl-card-iframe');
                    if (document.body.contains(node)) {
                        vm.validateBeforeSubmit = vm._autoClickAddCardAndReturnNotValidated;
                    } else {
                        setTimeout(test, 500);
                    }
                }, 500);
            }

            this.readyToInject = true;
            this.injectToDOM();
        });
        logger.logInfo(`Mounted the Payfurl JSSDK`);
    },

    injectToDOM() {
        this.injectPaymentGatewayButton('#payfurl-wrapper', 'clicktopay-credit-card', {
            callback: () => {
                this.isButtonEnabled = true;
            }
        })
    },

    _autoClickAddCardAndReturnNotValidated(callback) {
        logger.logInfo(`GfClickToPayCreditCard._autoClickAddCardAndReturnNotValidated()`, callback)
        var addCardSubmit = document.getElementById('addCard-submit');
        addCardSubmit.click();
        this.callbackFromValidateBeforeSubmit = callback;
        return false;
    },

    generateToken: function() {
      this.debouncedTokenRequest();
    },

    _generateToken: function() {
      this.clickToPayPaymentRequestor.tokenizeRequest(this.hostedFieldInstance, this.onPaymentSuccessfulRequest, this.onPaymentError);
    },

    onPaymentSuccessfulRequest(payload) {
      this.lastUpdatedNonce = payload.nonce;
      this.lastUpdatedCardType = this.normaliseCard(payload.details.cardType);
      this.lastUpdatedDigits = payload.details.lastFour;
      this.lastUpdatedExpiry = `${payload.details.expirationMonth}/${payload.details.expirationYear}`;
      this.notifyPaymentDetailsUpdatedIfValid();
    },

    notifyPaymentDetailsUpdatedIfValid: function() {
      this.cardholderNameInvalid = false;
      this.onPaymentMethodReceived(this.lastUpdatedNonce, this.lastUpdatedCardType, this.lastUpdatedDigits, this.lastUpdatedExpiry, this.additionalData);
    },
    normaliseCard(cardType) {
        // Return default as MASTERCARD as w/o this token logic won't work as well as all other logic
        if (cardType == null || cardType === undefined) {
            return GfCardTypes.types.MASTERCARD;
        }
        switch (cardType.toLowerCase()) {
            case "amex":
                return GfCardTypes.types.AMEX;
            case "mastercard":
                return GfCardTypes.types.MASTERCARD;
            case "visa":
                return GfCardTypes.types.VISA;
            default:
                // The same here, for example, if card type is MAESTRO we cannot return OTHERS as the logic won't work
                return GfCardTypes.types.MASTERCARD;
        }
     },
    getClickToPayOptions: function (cardBrands, dpaBillingPreference, saveCard) {
        if (!dpaBillingPreference)
            dpaBillingPreference = "NONE";
        if (!saveCard)
            saveCard = false;

        return {
            cardBrands: cardBrands,
            confirmPayment: false,
            locale: "en_AU",
            savedCardsOptions: { align: "center" },
            dpaBillingPreference: dpaBillingPreference,
            saveCard: saveCard
        }
    },
  }
}
</script>

<style lang="css" scoped>
input.invalid {
  border-color: red;
}

.hosted-field {
  border-radius: 5px;
  height: 34px;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  display: inline-block;
  box-shadow: none;
  font-weight: 400;
  font-size: 13.33px;
  font-family: 'Arial';
  line-height: 20px;
  transition: all 300ms ease-in-out;
}

.invalid {
  border-color: red;
  background-color: #fee;
}

.braintree-hosted-fields-valid {
  border-color: #128d01;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 0px;
}

.switch input {
  display:none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: #fafafa;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#card-name-selector-wrap li {
  list-style-type: none;
}

input[disabled], div[disabled] {
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}

#payfurl-button-container-disabled .payfurl-buttons.payfurl-buttons-context-iframe.payfurl-buttons-label-unknown.payfurl-buttons-layout-vertical {
  filter: opacity(0.5);
}

#payfurl-button-container-disabled, #payfurl-button-container {
  text-align: center;
}

</style>
