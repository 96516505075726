<template>
  <div
    v-if="settingsLoaded"
    id="gf-epl-address-details"
    class="col-12 m-0 p-0"
  >
    <gf-address
      :settings="addressLookupSettings"
      @addressChanged="onAddressChanged"
      @onLoadError="onLoadError"
    />
  </div><!-- #gf-epl-address-details -->
</template>

<script>
import logger from '../../../../common/logger';
import ModuleBase from "../ModuleBase.vue";
import GfConfigurationClient from '../../../../common/GfConfigurationClient.vue';
import GfAddress from "@gf/gf-common-vue-components/src/components/Address/Address.vue";

export default {
  name: "Address",
  components: {
    GfAddress
  },
  mixins: [ModuleBase, GfConfigurationClient],
  data() {
    return {
      settingsLoaded: false,
      showSingleLineAddress: true,
      hasInitializationError: false,
      hasPrefillData: false,
      Address: '',
      AddressLine1: '',
      AddressLine2: '',
      Suburb: '',
      Postcode: '',
      State: '',
      Country: '',
      GoogleAPI: {
        Key: ''
      },
      entityConfigSettings: [
        {
          type: "Credentials",
          subType: "Location"
        }
      ],
      listSettings: [],
      addressLookupSettings: {
        isRequired:true,
        lookupType: "google",
        addressType: "lookup",
        fields: {
          addressLine1:{id:'street_number', name:'street_number', placeholder: 'Street Address', value:'', isVisible: true, isRequired: true },
          addressLine2:{id:'addressLine2', name:'addressLine2', placeholder: 'Address Line 2', value:'', isVisible: true, isRequired: false },
          suburb: {id:'route', name:'route', placeholder: 'City', value:'',  isVisible: true, isRequired: true },
          postcode:{id:'postal_code', name:'postal_code', placeholder: 'Postal Code', value:'', label:'Zip Code', isVisible: true, isRequired: true },
          state:{id:'administrative_area_level_1', name:'administrative_area_level_1', placeholder: 'State', value:'', label:'State', isVisible: true, isRequired: true },
          country:{id:'country', name:'country', placeholder: 'Country', value:'', isVisible: true, isRequired: true }
        },
        key: '',
        expandOnSelect: false
      }
    }
  },
  watch: {
    Address(val) {
        this.$nextTick(async () => {
            await this.$ModuleBase_validate();
        });

    }
  },

  mounted: function() {
    this.applyAddressFieldSettings();
  },

  created: function() {
    this.applyAddressTypeSettings();
  },

  validators: {
    Address(value) {
      if (this.settings.isRequired) {
        return this.validator.value(value).required();
      }
    },

    AddressLine1(value) {
      if (this.settings.isRequired) {
        return this.validator.value(value).required();
      }
    },

    AddressLine2(value) {
    },

    Suburb(value) {
      if (this.settings.isRequired) {
        return this.validator.value(value).required();
      }
    },

    State(value) {
      if (this.settings.isRequired) {
        return this.validator.value(value).required();
      }
    },

    Postcode(value) {
      if (this.settings.isRequired) {
        return this.validator.value(value).required();
      }
    },

    Country(value) {
      if (this.settings.isRequired) {
        return this.validator.value(value).required();
      }
    }
  },

  methods: {
    applyAddressTypeSettings() {
        if (this.settings != null && this.settings.addressType){
            this.addressLookupSettings.addressType = this.settings.addressType;
        }
    },
    applyAddressFieldSettings() {
        if (this.settings != null && this.settings.fields != null && Array.isArray(this.settings.fields)) {
            this.settings.fields.forEach(field => {
                const { name: fieldName, isVisible, isRequired } = field;
                if (this.addressLookupSettings.fields[fieldName]) {
                    Object.assign(this.addressLookupSettings.fields[fieldName], { isVisible, isRequired });
                }
            });
        }
    },
    $ModuleBase_handleEntityConfigResults(results) {
      this.listSettings = results;
      this.getGoogleAPIKey();
    },

    $ModuleBase_handlePrefillData(settings) {
      this.logger.logInfo('Address.$ModuleBase_handlePrefillData.settings', settings)
      this.hasPrefillData = true

      for (let prop in settings) {
        this.logger.logInfo('Address.$ModuleBase_handlePrefillData.props', prop)
        if (typeof this.addressLookupSettings.fields[prop] != 'undefined')
          this.addressLookupSettings.fields[prop].value = settings[prop]
      }

      this.onAddressChanged(settings)
      this.hasPrefillData = false
    },

    async getGoogleAPIKey() {
      logger.logInfo('Getting Google API key, settings:', this.listSettings[0].Settings);
      let settings = JSON.parse(this.listSettings[0].Settings);
      this.GoogleAPI.Key = settings.find(x => x.type == "Google").settings.key;
      this.addressLookupSettings.key = settings.find(x => x.type == "Google").settings.key;
      this.settingsLoaded = true;
    },

    toggleLineAddress() {
      this.showSingleLineAddress = ! this.showSingleLineAddress;
			this.$nextTick(async () => {
				await this.$ModuleBase_validate();
			});
    },

    onAddressChanged(val) {
      this.logger.logInfo('onAddressChanged', val);
      this.Address = val.address || '';
      this.AddressLine1 = val.addressLine1 || '';
      this.AddressLine2 = val.addressLine2 || '';
      this.Suburb = val.suburb || '';
      this.Postcode = val.postcode || '';
      this.Country = val.country || '';
      this.State = val.state || '';

      if (this.hasInitializationError || this.hasPrefillData) {
        const addressSegments = [
          val.addressLine1,
          val.addressLine2,
          val.suburb,
          val.state,
          val.country,
          val.postcode
        ]

        this.Address = addressSegments.join(' ').replace(/\s+/g, ' ').trim()
      }

      this.store.updateAddress(val);
			this.$nextTick(async () => {
        await this.$ModuleBase_validate();
			});
    },

    onLoadError() {
      this.hasInitializationError = true
    }
  }
}
</script>

<style>
#gf-epl-address-details input[type="text"] {
	margin: 0;
  outline: 0;
  tap-highlight-color: hsla(0,0%,100%,0);
  background: #fff;
  border: 1px solid rgba(34,36,38,.2);
  color: #000;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: inset 0 0 0 0 transparent;
  -webkit-transition: color .1s ease,border-color .1s ease;
  transition: color .1s ease,border-color .1s ease;
  line-height: 1.6em;
  padding: 13px;
	width: 99.8%;
}

.field{
	padding-bottom: 24px;
}

.field label{
	margin-bottom: 5px;
}

.pac-container {
    background-color: #fff;
    position: absolute!important;
    z-index: 1000;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden
}

.pac-container:after{
    content:"";
    padding:1px 1px 1px 0;
    height:16px;
    text-align:right;
    display:block;
    background: url(https://developers.google.com/maps/documentation/places/images/powered-by-google-on-white.png) no-repeat center right cover;
}

.pac-logo:after {
    content: "";
    padding: 1px 1px 1px 0;
    height: 16px;
    text-align: right;
    display: block;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 120px 14px
}
.hdpi.pac-logo:after {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png)
}
.pac-item {
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 11px;
    color: #999
}
.pac-item:hover {
    background-color: #fafafa
}
.pac-item-selected,
.pac-item-selected:hover {
    background-color: #ebf2fe
}
.pac-matched {
    font-weight: 700
}
.pac-item-query {
    font-size: 13px;
    padding-right: 3px;
    color: #000
}
.pac-icon {
    width: 15px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;
    display: inline-block;
    vertical-align: top;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
    background-size: 34px
}
.hdpi .pac-icon {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png)
}
.pac-icon-search {
    background-position: -1px -1px
}
.pac-item-selected .pac-icon-search {
    background-position: -18px -1px
}
.pac-icon-marker {
    background-position: -1px -161px
}
.pac-item-selected .pac-icon-marker {
    background-position: -18px -161px
}
.pac-placeholder {
    color: gray
}
</style>
