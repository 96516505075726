<template>
  <div v-if="!loadFailure">
    <div id="googlepay-wrapper" />
    <div
      id="gfPresetGooglePayButton"
      class="payment-button-wrapper"
      :data-type="paymentTypeName"
      :disabled="isDisabled"
    />
  </div>
</template>
<script>
import GfPaymentComponent from '../../../common/GfPaymentComponent.vue';
import GfBraintreeGooglePayInitializer from './GfBraintreeGooglePayInitializer.vue'
import logger from '../../../common/logger'

export default {
  name: 'GfBrainTreeGooglePay',
  mixins: [GfPaymentComponent, GfBraintreeGooglePayInitializer],
  data() {
    return {
      paymentMethod: 'GP',
      paymentMethodButtonSelector: '#gfPresetGooglePayButton',
      paymentTypeName: 'braintree-googlepay',
      readyToInject: false,
      isInjectedToDom: false,
      isLoading: false,
      paymentJwt: null,
      merchantOrigin: ''
    }
  },
  async mounted() {
    this.isLoading = true
    await this.loadGooglePayScripts()
    this.createClient()
  },
  methods: {
    isAncestorHidden(element) {
      if (!element) return false

      if (element.style.display === "none" || element.style.visibility === "hidden") {
        return true
      }

      return this.isAncestorHidden(element.parentElement)
    },
    injectToDOM() {
      this.injectPaymentGatewayButton(
        this.paymentMethodButtonSelector,
        this.paymentTypeName,
        {
          callback: () => {
            this.isInjectedToDom = true
            this.onPaymentMethodReceived(Date.now().toString(), "", "", "", { forceSubmit: false })
          }
        }
      )
    },
    $_GfPaymentComponent_onPendingClientActions(data) {
      logger.logInfo(`GfBraintreeGooglePay, onPendingClientActions:`, data);
      this.paymentJwt = data.PaymentClientSecret;
      this.merchantOrigin = data.AdditionalData.MerchantOrigin;
    }
  }
}
</script>
<style lang="css">

@keyframes placeHolderShimmer{
  0% { background-position: -468px 0 }
  100% { background-position: 468px 0 }
}
.braintreePPFlexBox{
  display: flex;
  flex-flow: row;
}
.braintreePPFlexBox p{
  white-space: pre;
  margin-bottom: 0px;
}
.animated-placeholder,.animated-placeholder-user {
  display: none;
  margin: 0 auto;
  background-color: #eee;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eee 10%, #ddd 18%, #eee 10%);
  background-size: 800px 104px;
  height: 16px;
  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  width: 100%;
}

input[disabled], div[disabled], p[disabled] {
  opacity: 0.6;
  pointer-events: none;
  touch-action: none;
}

#gfPresetGooglePayButton {
  height: 48px;
  width: 100%;
  max-width: 800px;
}

.alternate-button-container:has(#gfPresetGooglePayButton) {
  display: flex;
  justify-content: center;
}
</style>
