<script>
import { Client } from 'braintree-web/client';
import { DataCollector } from 'braintree-web/data-collector';
import VS2 from '@gf/vue-script2';
import logger from '../../../common/logger'
import EmbeddedPaymentStore from "../../embedded-payment-loader/EmbeddedPaymentStore.js";
import EmbeddedPaymentConstants from '../../embedded-payment-loader/EmbeddedPaymentConstants';
import updatePaymentRequestType from "../../../common/updatePaymentRequestTypes.js";
import GfPaymentTypes from "../../../common/GfPaymentTypes";

export default {
  name: 'GfBrainTreeApplePayInitializer',
  components: { Client, DataCollector },
  data() {
    return {
      braintree: {
        client: null,
        dataCollector: null,
      },
      braintreeInstance: null,
      additionalResponseData: {},
      tokenizeError: this.onTokenizeError,
      isTokenized: false,
      loadFailure: false,
      store: EmbeddedPaymentStore,
      isPaymentGatewaySupported: true,
      applePayInstance: null,
      applePaySession: null,
      supportedVersion: 3,
      paymentAmount: -1,
    }
  },

  computed:{
    deviceDataRes:{
      get: function(){
        return this.additionalResponseData;
      },
      set: function(id){
        var data = this.additionalResponseData;
        data.device_session_id = id.device_session_id;
        data.fraud_merchant_id = id.fraud_merchant_id;
        data.correlation_id = id.correlation_id;
        this.additionalResponseData = data;
      }
    }
  },
  async mounted() {
    logger.logInfo('GfBraintreeApplePayInitializer, mounted');
    var vm = this;
    await vm.loadApplePayScripts();
    vm.createClient();
  },
  methods: {
    async loadApplePayScripts() {
      logger.logInfo('GfBraintreeApplePayInitializer, loadApplePayScripts');
      await Promise.all([
        VS2.load('https://js.braintreegateway.com/web/3.97.3/js/client.min.js'),
        VS2.load('https://js.braintreegateway.com/web/3.97.3/js/apple-pay.min.js'),
        VS2.load('https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js')
      ]);
    },
    async pendingClientActionStatusRecieved() {
      logger.logInfo("GfBraintreeApplePayInitializer, executing pendingClientActionStatusRecieved");
      var statusReceived = false;
      while (!statusReceived) {
        await new Promise(resolve => setTimeout(resolve, 250));
        if (this.pendingClientActionRecieved) {
          logger.logInfo("GfBraintreeApplePayInitializer, pendingClientActionStatusRecieved, pendingClientActionRecieved: ", this.pendingClientActionRecieved);
          statusReceived = true
        }
      }
      return this.pendingClientActionRecieved;
    },
    setBraintreeInstance() {
      this.braintreeInstance = typeof braintree != 'undefined' ? braintree : null
    },

    setApplePaySession() {
      this.applePaySession = typeof ApplePaySession != 'undefined' ? ApplePaySession : null
    },

    createClient: function () {
      var vm = this;
      var merchantId = _.get(vm, 'additionalProperties.merchantId', '')

      logger.logInfo('GfBraintreeApplePayInitializer, createClient')

      this.setBraintreeInstance()
      this.setApplePaySession()

      logger.logInfo('GfBraintreeApplePayInitializer, createClient, vm.tokenizationKey: ', vm.tokenizationKey);
      logger.logInfo('GfBraintreeApplePayInitializer, createClient, merchantId: ', merchantId);
      logger.logInfo('window.ApplePaySession: ', vm.applePaySession);

      if (vm.applePaySession && vm.braintreeInstance) {
        logger.logInfo('Apple Pay is supported and available in the browser');

        if (vm.applePaySession.supportsVersion(vm.supportedVersion)) {
          logger.logInfo('This device supports version ' + vm.supportedVersion + ' of Apple Pay');
        } else {
          logger.logInfo('This device does not support version ' + vm.supportedVersion + ' of Apple Pay');
        }

        vm.applePaySession.canMakePaymentsWithActiveCard(merchantId)
          .then(canMakePayments => {

            logger.logInfo('GfBraintreeApplePayInitializer, canMakePaymentsWithActiveCard, canMakePayments', canMakePayments);

            vm.readyToInject = true

            if (canMakePayments) {
              if (vm.applePaySession.supportsVersion(vm.supportedVersion)) {
                vm.isPaymentGatewaySupported = true
              }
            } else {
              if (vm.applePaySession.canMakePayments()) {
                logger.logInfo('GfBraintreeApplePayInitializer, canMakePaymentsWithActiveCard, ApplePaySession.canMakePayments()', canMakePayments);
                vm.isPaymentGatewaySupported = true
              }
            }

            if (vm.isPaymentGatewaySupported === true) {
              logger.logInfo('Creating clientInstance, clientAuthorization: ', vm.tokenizationKey);

              vm.braintreeInstance.client.create({
                authorization: vm.tokenizationKey
              }, function (clientErr, clientInstance) {
                if (clientErr) {
                  logger.logInfo('Error creating client:', clientErr);
                  return;
                }

                logger.logInfo('clientInstance is successfully created: ', clientInstance);
                logger.logInfo('creating applePayInstance');

                vm.braintreeInstance.applePay.create({
                  client: clientInstance
                }, function (applePayErr, applePayInstance) {
                  if (applePayErr) {
                    logger.logInfo('Error creating applePayInstance:', applePayErr);
                    return;
                  }
                  logger.logInfo('applePayInstance is successfully created: ', applePayInstance);
                  vm.applePayInstance = applePayInstance

                });
              });

              if (this.isInjectedToDom === false) {
                this.injectToDOM();
              }
            }
          }).catch(err => {
            logger.logInfo('GfBraintreeApplePayInitializer, canMakePaymentsWithActiveCard, catch, err ', err)
          })
      } else {
        logger.logInfo('Apple Pay is not supported or available in the browser');
        vm.isPaymentGatewaySupported = false
        vm.readyToInject = true
      }

    },

    onApplePayCancelled() {
      var vm = this
      vm.onClientActionsResponseReceived(false, "failed to make payment", 'error occured');
      vm.onPaymentUpdateRequest(updatePaymentRequestType.CANCELLED)
      vm.onPaymentCancelled(true)
    },

    async onApplePayButtonClicked(evt) {
      logger.logInfo('onApplePayButtonClicked');
      if (!this.applePaySession)
        return

      var vm = this
      if (vm.isValid() === false) {
        logger.logInfo("onApplePayButtonClicked, isValid is FALSE")
        return;
      }
      vm.onPaymentMethodReceived(
        Date.now().toString(),
        GfPaymentTypes.APPLEPAY,
        "",
        "",
        { forceSubmit: true }
      );
      
      var amount = this.store.state.totalAmount
      var currentCode = this.store.state.currencyInfo.code
      logger.logInfo('Amount is : ',amount);
      logger.logInfo('currentCode is : ',currentCode);
      var paymentRequest = this.applePayInstance.createPaymentRequest({
        total: {
          label: _.get(this, 'additionalProperties.businessName', EmbeddedPaymentConstants.BusinessName),
          amount: amount.toString()
        },
        currencyCode: _.get(this, 'additionalProperties.currencyCode', currentCode),
        requiredBillingContactFields: ["postalAddress"]
      });
      logger.logInfo(paymentRequest);

      var session = new vm.applePaySession(vm.supportedVersion, paymentRequest);
      session.begin();

      session.onvalidatemerchant = async (event) => {
        await this.pendingClientActionStatusRecieved();
        this.applePayInstance.performValidation({
          validationURL: event.validationURL,
          displayName: _.get(this, 'additionalProperties.businessName', EmbeddedPaymentConstants.BusinessName),
        }, (err, merchantSession) => {
          if (err) {
            logger.logInfo('ApplePay onvalidatemerchant error', err);
            vm.onApplePayCancelled();
            return;
          }
          session.completeMerchantValidation(merchantSession);
        });
      };

      session.onpaymentauthorized = (event) => {
        logger.logInfo('Your shipping address is:', event.payment.shippingContact);
        this.applePayInstance.tokenize({
          token: event.payment.token
        }, (tokenizeErr, payload) => {
          if (tokenizeErr) {
            logger.logInfo('Error tokenizing Apple Pay:', tokenizeErr);
            vm.onApplePayCancelled();
            session.completePayment(ApplePaySession.STATUS_FAILURE);
            return;
          }

          logger.logInfo('nonce:', payload.nonce);
          vm.onPaymentUpdateRequest(updatePaymentRequestType.TOKENIZED, { "nonce": payload.nonce })
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
        });
      };

      session.oncancel = (event) => {
        logger.logInfo("onApplePayButtonClicked - oncancel:", event);
        vm.onApplePayCancelled();
      };


      

    },

  }
}
</script>
