<template>
  <div
    :class="settings.required ? 'required field' : 'field'"
    class="w-100 p-0"
  >
    <span
      class="d-block"
      v-html="settings.description"
    />
    <div
      v-if="settings.displayType === constants.CustomQuestionOptionsDisplayType.Radio"
      class=" pt-2"
    >
      <div
        v-for="(option, optionIndex) in settings.options"
        :key="optionIndex"
        class="pb-1 custom-radio"
      >
        <!-- Hidden native radio input -->
        <input
          :id="`option-${optionIndex}`"
          v-model="selectedValue"
          type="radio"
          :value="option.value"
          :name="settings.uniqueIdentifier"
          tabindex="0"
          class="form-check-input options-radio"
        >

        <!-- Label with custom outer circle for border and inner dot -->
        <label
          :for="`option-${optionIndex}`"
          class="form-check-label options-label"
        >
          <!-- Outer circle with conditional gf-secondary class -->
          <div
            class="radio-outer-circle"
            :class="{'radio-outer-circle--default gf-secondary-bg': selectedValue === option.value}"
          >
            <!-- Inner dot, visible only when selected -->
            <div
              v-if="selectedValue === option.value"
              class="radio-inner-dot"
            />
          </div>
          {{ option.text }}
        </label>
      </div>
    </div>

    <div v-if="settings.displayType === constants.CustomQuestionOptionsDisplayType.Select">
      <select
        :id="settings.uniqueIdentifier"
        v-model="selectedValue"
        class="form-control options-select"
      >
        <option
          v-for="(option, optionIndex) in settings.options"
          :key="optionIndex"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import ModuleBase from "../ModuleBase.vue";

export default {
  name: "Options",
  mixins: [ModuleBase],
  props:{
    values: {
      type: Object,
      default: function () {
        return { }
      }
    }
  },
  data() {
    return {
      selectedValue: ''
    }
  },
  watch: {
    selectedValue() {
      this.storeUpdatedValue()
      this.$nextTick(async () => {
        await this.$ModuleBase_validate()
      })
    }
  },
	mounted() {
		this.logger.logInfo(this.settings,'Dropdown component loaded')
		if (this.settings.defaultValue !== undefined && this.settings.defaultValue !== null)
			this.selectedValue = this.settings.defaultValue
	},

  methods: {
    storeUpdatedValue() {
      var metaDataValue = {
        metaType: this.constants.PaymentMetaType.Custom,
        metaValueType: this.constants.PaymentMetaValueType.String,
        name: this.settings.uniqueIdentifier,
        description: this.settings.description,
        value: this.selectedValue
      }
      this.store.updateCustomQuestionValue(metaDataValue)
    },
  },
  validators: {
    selectedValue(val) {
      if (this.settings.required) {
        return this.validator
          .value(val)
          .required()
      }
    }
  }
};
</script>
<style lang="css">

.options-select{
    min-height: 54px;
}


.custom-radio .form-check-input {
  display: none;
}


.custom-radio .form-check-label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}


.radio-outer-circle {
  width: 22px;
  height: 22px;
  border: 2px solid #cccccc;
  border-radius: 50%;
  margin-right: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.radio-outer-circle--default{
    background-color: #1388cd;
}

.custom-radio .gf-secondary-bg {
  border-color: transparent
}


.custom-radio .radio-inner-dot {
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
}

</style>
